import Vue from 'vue'
import Router from 'vue-router'
import Main from '../views/Main'

Vue.use(Router)

const routes = [
    {
        path: '/',
        name: 'Main',
        component: Main
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/Login.vue')
    },
    {
        path: '/explain',
        name: 'explain',
        component: () => import('../views/Explain.vue')
    },
    {
        path: '/exam',
        name: 'exam',
        component: () => import('../views/Exam.vue')
    },
    {
        path: '/matrix',
        name: 'matrix',
        component: () => import('../views/Matrix.vue')
    },
    {
        path: '/cobweb',
        name: 'cobweb',
        component: () => import('../views/Cobweb.vue')
    },
    {
        path: '/scores',
        name: 'scores',
        component: () => import('../views/Scores.vue')
    },
]

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})
export default router

