import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: "",
    userInfo:"",
    resultId: 0,
    staffInfo: ""
  },
  mutations: {
    setToken(state, value){
      // 更新token
      state.token = value;
      localStorage.setItem("token",value);
    },
    setUserInfo(state, value) {
      // 更新token
      state.userInfo = value;
      localStorage.setItem("userInfo",value);
    },
    setResultId(state, value) {
      state.resultId = value;
      localStorage.setItem("resultId",value)
    },
    setStaffInfo(state, value) {
      state.staffInfo = value
      localStorage.setItem("staffInfo",value)
    }
  },
  actions: {
  },
  modules: {
  }
})
