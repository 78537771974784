<template>
  <div v-myTitle :data-title="textTitle">
    <div class="main">
<!--      <h1 class="title">TACK</h1>-->
      <h1 class="title" style="margin-top: 100px;">{{ this.$title }}</h1>
      <div class="select">请选择您的身份</div>
      <div class="manage" @click="goTo(1)">{{ this.$staff }}</div>
      <div class="manage" @click="goTo(2)">{{ this.$manager }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Main",
  data() {
    return {
      textTitle: this.$title
    }
  },
  methods: {
    goTo(type) {
      this.$router.push({ name: "login", params: { type: type } });
    },
  },
};
</script>

<style>
.main {
  background: url("../assets/img/index1.jpg");
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background-size: cover;
  position: absolute;
}
.title {
  color: #0089c4;
  margin: 0;
}
.manage {
  width: 80%;
  height: 40px;
  margin: 40px auto;
  text-align: center;
  line-height: 40px;
  color: #fff;
  background-color: #53b0e4;
  border-radius: 5px;
  font-weight: bold;
  border: 1px solid rgb(255, 255, 255);
  box-shadow: 0 0 0 2px rgb(255, 255, 255), 0 0 5px 3px rgb(37, 161, 221);
  background-image: linear-gradient(#00B3F1, #00A3EF);
}
.select {
  margin-top: 20px;
}
</style>
